<template>
  <div class="page--not--found__container">
    <div
      class="
        page--not--found
        md:w-3/5
        w-11/12
        mr-auto
        ml-auto
        flex
        h-screen
        items-center
      "
    >
      <div class="content">
        <h1
          class="
            md:text-5xl
            text-3xl
            font-merriweather font-black
            leading-normal
          "
        >
          I'm sorry, but you're at the wrong place!
        </h1>
        <p class="mt-16 md:w-4/5 md:text-xl text-gray-700 leading-normal">
          Seems like you have entered a wrong URL. Check the URL and see if
          there is any error in the spelling. It might be that you're looking
          for something that doesn't exist.
        </p>
        <div class="useful-links mt-12 text-xl flex flex-col">
          <router-link to="/" class="link-base">Home</router-link>
          <a
            href="https://blog.deepjyoti30.dev"
            class="link-base mt-6"
            rel="noopener noreferrer"
            >Blog =></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>
