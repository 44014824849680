<template>
  <div class="not--found__container">
    <page-not-found />
  </div>
</template>

<script>
import PageNotFound from "../components/PageNotFound.vue";
export default {
  components: { PageNotFound },
  name: "NotFound",
};
</script>
